<template>

    <div class="filters mb-3">
        <div class="row">

            <div class="col-lg">
                <input type="text" class="form-control" name="search" id="search" @keyup="searchbar()"
                       v-model="search" :placeholder="placeholder"/>
            </div>

            <div class="col-lg-2">
                <select name="type" id="type" v-model="type" @change="getSchedules(1)" class="form-control">
                    <option value="">Alle types</option>
                    <option v-for="schedule_type in schedule_types" :value="schedule_type.value">{{
                            schedule_type.label
                        }}
                    </option>
                </select>
            </div>

            <div class="col-lg-2 d-flex align-items-center">
                <div class="form-check">
                    <input id="showHistory" type="checkbox" class="form-check-input" v-model="showHistory">
                    <label for="showHistory" class="form-check-label">Toon oude planningen</label>
                </div>
            </div>
        </div>
    </div>

    <a v-if="canCreateSchedule" :href="route('schedules.create')" class="btn btn-success mb-3">Nieuwe planning
        aanmaken
        <i class="far fa-angle-right"></i>
    </a>

    <a :href="route('schedules.map')" class="btn btn-secondary mb-3 ml-3">Planning kaart
        <i class="far fa-map"></i>
    </a>

    <div class="list-row" v-if="schedules.length" v-for="schedule in schedules">
        <div class="row align-items-center">
            <div class="col-8">
                <strong v-if="schedule.date.isToday">Vandaag</strong>
                <strong v-else-if="schedule.date.isTomorrow">Morgen</strong>
                <strong v-else>{{ schedule.date.formatted }}</strong>
                - {{ schedule.number }} - {{ schedule.name }}
                <div v-if="schedule.date.date !== schedule.original_date?.date && schedule.original_date?.date">
                    | Originele datum: {{ schedule.original_date.formatted }}
                </div>
                <template v-for="schedule_type in schedule_types">
                    <span :class="'mx-2 badge badge-'+schedule_type.color" v-if="schedule.type === schedule_type.value">{{
                            schedule_type.label
                        }}</span>
                </template>
                <span v-if="schedule.status === 1" class="badge badge-warning">
                    <i class="fas fa-check" style="position: relative; top: 1px;"></i>definitief</span>
            </div>
            <div class="col-4">
                <div>
                    <span v-if="schedule.orders.length === 0">geen</span>
                    <span v-else>{{ schedule.orders.length }}</span>
                    orders in planning
                </div>
                <div>
                    Door {{ schedule.responsible_party_name }}
                </div>
            </div>
            <div class="col-lg-12 mt-1" v-if="schedule.note">
                {{ schedule.note }}
            </div>
            <div class="col-lg-12 d-flex flex-wrap mt-1 gap-2">
                <div v-if="canEditSchedule">
                    <div v-if="schedule.status === 1">
                        <a class="btn btn-sm btn-primary"
                           :href="route('schedules.edit',schedule.id)">
                            <i class="far fa-eye"></i>
                            <small>
                                Bekijken
                            </small>
                        </a>
                    </div>
                    <div v-else>
                        <a class="btn btn-sm btn-primary"
                           :href="route('schedules.edit',schedule.id)">
                            <i class="far fa-pencil"></i>
                            <small>
                                Bewerken
                            </small>
                        </a>
                    </div>
                </div>
                <template v-if="schedule.orders.length > 0">
                    <div v-if="canViewScheduleRoute && schedule.is_delivery">
                        <a class="btn btn-sm btn-secondary"
                           :href="route('schedules.route',[schedule.id, schedule.check_key])">
                            <i class="far fa-route"></i>
                            <small>
                                Route
                            </small>
                        </a>
                    </div>
                    <div v-if="canViewScheduleRoute && schedule.type === 'pickup'">
                        <a class="btn btn-sm btn-secondary"
                           :href="route('schedules.pickup-list', schedule.id)">
                            <i class="far fa-person-carry"></i>
                            <small>
                                Afhalingen
                            </small>
                        </a>
                    </div>
                    <div v-if="canViewSchedulePackingslip">
                        <a class="btn btn-sm btn-secondary" target="_blank"
                           :href="route('schedules.bon',schedule.id)">
                            <i class="far fa-file-pdf"></i>
                            <small>
                                Pakbon
                            </small>
                        </a>
                    </div>


                    <div v-if="canViewScheduleProductionlist && schedule.has_ma_list">
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary dropdown-toggle"
                                    type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                <i class="far fa-file-spreadsheet"></i>
                                <small>
                                    MA-lijst {{ schedule.type === 'pickup' ? 'afhalen' : 'bezorgen' }}
                                </small>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" target="_blank"
                                   :href="route('schedules.production-list', [schedule.id])">
                                    <strong>Standaard MA-lijst</strong>
                                </a>
                                <a class="dropdown-item" target="_blank"
                                   :href="route('schedules.production-list', [schedule.id, 'full'])">
                                    Volledige MA-lijst
                                </a>
                                <div v-for="production_list_option in production_list_options">
                                    <a class="dropdown-item" target="_blank"
                                       :href="route(
                                           'schedules.production-list', [
                                               schedule.id,
                                               production_list_option[0],
                                               production_list_option[1]
                                       ])">
                                        {{ production_list_option[2]}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="canViewScheduleTimeslots && schedule.has_timeslots">
                        <a class="btn btn-sm btn-secondary" target="_blank"
                           :href="route('timeslot.pdf',schedule.id)">
                            <i class="far fa-truck"></i>
                            <small>
                                Tijdslots
                            </small>
                        </a>
                    </div>

                    <div v-if="canMoveSchedule && (canUnconfirmSchedule || !schedule.status )">
                        <a class="btn btn-sm btn-warning" :href="route('schedules.show-move', schedule.id)">
                            <i class="far fa-calendar-edit"></i>
                            <small>
                                Datum wijzigen
                            </small>
                        </a>
                    </div>

                    <template v-if="schedule.is_delivery">
                        <div v-if="canConfirmSchedule && schedule.status !== 1">
                            <a class="btn btn-sm btn-danger"
                               :href="route('timeslot.show', schedule.id)">
                                <i class="far fa-clipboard-check"></i>
                                <small>
                                    Definitief
                                </small>
                            </a>
                        </div>
                        <div v-if="canUnconfirmSchedule && schedule.status === 1">
                            <form method="post"
                                  :action="route('timeslot.revert-status', schedule.id)">
                                <input type="hidden" name="_token" :value="csrf">
                                <button class="btn btn-sm btn-outline-danger" type="submit"
                                        onclick="return confirm('Weet je zeker dat deze route niet meer definitief moet zijn?');">
                                    <i class="far fa-clipboard"></i>
                                    <small>
                                        Ont-definitief
                                    </small>
                                </button>
                            </form>
                        </div>
                    </template>
                </template>
            </div>

        </div>
    </div>

    <div v-else class="alert alert-info">Geen planningen gevonden</div>


    <div class="d-flex justify-content-end mt-3">
        <div v-if="pagination.amount_pages > 1" class="list-item list-pagination">
            <ul role="navigation" class="pagination">
                <li class="page-item" v-for="page in pagination.pages"
                    :class="pagination.current_page == page ? 'active' : ''"><a @click="getSchedules(page)"
                                                                                class="page-link">{{ page }}</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'schedules_index',
    props: [
        "schedule_types",
        "production_list_options",
        "canCreateSchedule",
        "canEditSchedule",
        "canViewScheduleRoute",
        "canViewSchedulePackingslip",
        "canViewScheduleProductionlist",
        "canViewScheduleTimeslots",
        "canConfirmSchedule",
        "canUnconfirmSchedule",
        "canMoveSchedule",
    ],
    computed: {
        placeholder() {
            return (this.showHistory ? 'Zoeken in oude planningen...' : 'Zoeken in toekomstige planningen...')
        },
    },
    data() {
        const query = new URLSearchParams(window.location.search);

        return {
            schedules: {},
            pagination:
                {
                    current_page: query.get('page') ?? 1,
                    total: '',
                    amount_pages: '',
                    pages: [],
                }
            ,
            search: query.get('s') ?? '',
            type: query.get('t') ?? '',
            showHistory: !!(query.get('h') ?? false),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        }
    },
    watch: {
        showHistory() {
            this.getSchedules(1);
        }
    },
    methods: {
        getSchedules(page) {
            if (page) {
                this.pagination.current_page = page;
            }

            window.history.pushState(
                {s: this.search},
                "",
                "?s=" + this.search
                + "&page=" + this.pagination.current_page
                + "&t=" + this.type
                + "&showHistory=" + (this.showHistory ? '1' : '0')
            );

            axios({
                method: 'get',
                url: route('schedules.json'),
                params: {
                    page: this.pagination.current_page,
                    search: this.search,
                    type: this.type,
                    showHistory: (this.showHistory ? '1' : '0'),
                }
            }).then((response) => {
                this.schedules = response.data.data;
                this.pagination.total = response.data.total;
                this.pagination.amount_pages = response.data.last_page;
                this.pagination.pages = this.pageRange();
            });
        },
        searchbar() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.getSchedules(1);
            }, 200);
        },
        pageRange() {
            if (this.pagination.amount_pages < 1) {
                return [1, 2, 3]
            }

            const current = parseInt(this.pagination.current_page);
            const last = this.pagination.amount_pages;
            const delta = 2;
            const left = current - delta;
            const right = current + delta + 1;
            const range = [];
            const pages = [];
            let l;
            for (let i = 1; i <= last; i++) {
                if (i === 1 || i === last || (i >= left && i < right)) {
                    range.push(i);
                }
            }
            range.forEach(function (i) {
                if (l) {
                    if (i - l === 2) {
                        pages.push(l + 1);
                    } else if (i - l !== 1) {
                        pages.push('...');
                    }
                }
                pages.push(i);
                l = i;
            });
            return pages;
        }
    },
    mounted() {
        this.getSchedules(this.pagination.current_page);
    }
};
</script>
